import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { rem, rgba } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'
import SVG from 'react-inlinesvg'

import { Container } from '../../Container'
import { HorizontalLine, VerticalLine } from '../Automation/icons'

import { MainColour, AltFont, PDBold, AltColour, Bold } from '../../../utils/variables'

import IconPhone from '../../../images/footer-phone.svg'
import IconAddress from '../../../images/footer-address.svg'
import IconEmail from '../../../images/footer-email.svg'

class FooterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199
    };
  }
  render() {
    const { isTablet } = this.state
    return (
      <>
        {!isTablet && <Lines />}
        <Icons />
      </>
    )
  }
}


export default FooterDetails

const Lines = () => (
  <FooterLines>
    <Controller>
      <Scene
        duration={50}
        offset={3800}
        reverse={false}
        triggerElement={null}
      // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              height: '0'
            }}
            to={{
              height: '100px'
            }}
            totalProgress={progress}
            paused
          >
            <VerticalLine />
          </Tween>
        )}
      </Scene>
      <Scene
        duration={100}
        offset={3830}
        reverse={false}
        triggerElement={null}
      // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              width: '0'
            }}
            to={{
              width: '1024px'
            }}
            totalProgress={progress}
            paused
          >
            <HorizontalLine style={{ marginTop: '100px' }} />
          </Tween>
        )}
      </Scene>
      <Scene
        duration={100}
        offset={3930}
        reverse={false}
        triggerElement={null}
      // indicators={true}
      >
        {(progress) => (
          <>
            <Tween
              from={{
                height: '0'
              }}
              to={{
                height: '348px'
              }}
              totalProgress={progress}
              paused
            >
              <VerticalLine style={{ marginTop: '102px', left: 'auto', transform: 'none' }} />
            </Tween>
            <Tween
              from={{
                height: '0'
              }}
              to={{
                height: '150px'
              }}
              totalProgress={progress}
              paused
            >
              <VerticalLine style={{ marginTop: '102px', left: 'auto', right: '0', transform: 'none' }} />
            </Tween>
          </>
        )}
      </Scene>
      <Scene
        duration={100}
        offset={4030}
        reverse={false}
        triggerElement={null}
      // indicators={true}
      >
        {(progress) => (
          <>
            <Tween
              from={{
                width: '0'
              }}
              to={{
                width: '820px'
              }}
              totalProgress={progress}
              paused
            >
              <HorizontalLine style={{ marginTop: '252px', left: 'auto', right: '0', transform: 'none' }} />
            </Tween>
            <Tween
              from={{
                width: '0'
              }}
              to={{
                width: '513px'
              }}
              totalProgress={progress}
              paused
            >
              <HorizontalLine style={{ marginTop: '450px', left: '0', transform: 'none' }} />
            </Tween>
          </>
        )}
      </Scene>
      <Scene
        duration={50}
        offset={4130}
        reverse={false}
        triggerElement={null}
      // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              height: '0'
            }}
            to={{
              height: '60px'
            }}
            totalProgress={progress}
            paused
          >
            <VerticalLine style={{ marginTop: '452px' }} />
          </Tween>
        )}
      </Scene>
      <Scene
        duration={200}
        offset={4180}
        reverse={false}
        triggerElement={null}
      // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              transform: 'scale(0)',
              opacity: '0'
            }}
            to={{
              transform: 'scale(1)',
              opacity: '1'
            }}
            totalProgress={progress}
            paused
          >
            <CircleEndpoint style={{ position: 'relative', top: '0', left: '0', margin: '521px auto 0' }} />
          </Tween>
        )}
      </Scene>
    </Controller>
  </FooterLines>
)

const FooterLines = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  width: 1024px;
  div {
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
  }
`

const CircleEndpoint = styled.div`
  height: 60px;
  width: 60px;
  background-color: ${rgba('#fff', 0.4)};
  border-radius: 60px;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &:before {    
    height: 10px;
    width: 10px;
    background-color: ${MainColour};
    border-radius: 10px;
  }
  &:after {
    height: 80px;
    width: 80px;
    border: 2px solid ${rgba('#fff', 0.4)};
    border-radius: 80px;
  }
`

export const Icons = (props) => (
  <StaticQuery
    query={graphql`
      query contactDetails {
        wordpressAcfOptions {
          options {
            general {
              address
              post_code
              phone_number
              email_address
            }
          }
        }
      }
    `}
    render={ data => 
      <FooterContent nopadding={props.nopadding}>
        <Container>
          <FooterIconList>
            <Controller>
              <Scene
                duration={200}
                offset={0}
                reverse={false}
                // indicators={true}
              >
                <Tween
                  staggerFrom={{
                    opacity: 0,
                    cycle: {
                      scale: [0, 0.5],
                    }
                  }}
                  stagger={0.1}
                >
                  <FooterIconCircle text={data.wordpressAcfOptions.options.general.phone_number}>
                    <a href={"tel:" + data.wordpressAcfOptions.options.general.phone_number} aria-label={"Call us on: " + data.wordpressAcfOptions.options.general.phone_number}>
                      <SVG src={IconPhone} />
                    </a>
                  </FooterIconCircle>
                  <FooterIconCircle text={data.wordpressAcfOptions.options.general.address + ' ' + data.wordpressAcfOptions.options.general.post_code} hidden768>
                    <SVG src={IconAddress} />
                  </FooterIconCircle>
                  <FooterIconCircle text={data.wordpressAcfOptions.options.general.email_address}>
                    <a href={"mailto:" + data.wordpressAcfOptions.options.general.email_address} aria-label={"Email us at: " + data.wordpressAcfOptions.options.general.email_address}>
                      <SVG src={IconEmail} />
                    </a>
                  </FooterIconCircle>
                </Tween>
              </Scene>
            </Controller>
          </FooterIconList>
        </Container>
      </FooterContent>
    }
  />  
)

const FooterContent = styled.div`
  position: relative;
  z-index: 10;
  text-align: center;
  ${props => !props.nopadding ? css`
    margin: ${rem('60px')} 0;
    @media (min-width: 1200px) {
      margin: ${rem('160px')} 0 ${rem('420px')} 0;
    } 
  ` : css`
    margin: 0 0 ${rem('150px')} 0;
  `}   
`

export const FooterIconList = styled.div`
  display: inline-block;
  width: auto;
  margin: 0 0 ${rem('75px')};
  @media (min-width: 576px) {
    margin: 0 0 ${rem('120px')};
  }  
  @media (min-width: 768px) {
    margin: 0;
  }
`

export const FooterIconCircle = styled.div`
  float: left;
  height: 180px;
  width: 180px;
  border-radius: 180px;
  background-color: ${AltColour};
  position: relative;
  /* margin: 0 ${rem('10px')} 0 0; */
  transform: scale(0.8);
  max-width: 50%;
  &:last-child {
    margin: 0;
  }
  &:before {
    content: '';
    height: 180px;
    width: 180px;
    border-radius: 180px;
    border: 2px solid ${AltColour};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all 0.3s ease;
    display: none;
    @media (min-width: 576px) {
      display: inline-block;
    }
  }
  &:after {
    content: '${props => props.text}';
    font-size: ${rem('24px')};
    /* font-family: ${AltFont};
    font-weight: ${PDBold}; */
    line-height: 1.2;
    /* font-style: italic; */
    font-weight: ${Bold};
    color: #fff;
    position: absolute;
    bottom: -55px;
    left: 50%;
    transform: translate(-50%,0);
    width: 160%;
    transition: all 0.3s ease;
    text-align: center;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all 0.3s ease;
  }
  &:hover {
    svg {
      transform: translate(-50%,-50%) scale(1.05);
    }  
    &:before {
      transform: translate(-50%,-50%) scale(1.1);
    }  
  }
  @media (min-width: 576px) {
    transform: none;
    margin: 0 ${rem('60px')} 0 0;
  }
  @media (min-width: 768px) {
    margin: 0 ${rem('90px')} 0 0;
  }
  ${props => props.hidden768 && css`
    display: none;
    @media (min-width: 992px) {
      display: inline-block;
    }
  `}
`