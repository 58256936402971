import React from 'react'
import { navigate } from 'gatsby-link'
import styled from 'styled-components'
import { rem, position } from 'polished'

import { ButtonInput } from '../Button'

// import { TitleColour, MainColour } from '../../utils/variables'

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    const data = this.props.data
    const name = this.props.name
    return (
      <Form
        name={name.replace(/\s+/g, '-').toLowerCase()}
        method="POST"
        action="/thank-you/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="form-name" value={name.replace(/\s+/g, '-').toLowerCase()} />
        <input type="hidden" name="bot-field" />
        {data.map((group, index) =>
          <Fields key={index}>
            {/* <Heading>
              <h4 dangerouslySetInnerHTML={{ __html: group.title }} />
            </Heading> */}
            {/* <FormLeft> */}
            {/* {group.wordpress_fields.filter(e => e.type !== 'textarea' && e.type !== 'message').map((item, index) => */}
            {group.wordpress_fields.map((item, index) =>
              <Field key={index} w={item.width ? item.width : '100%'} className={"field" + index}>
                {item.type === 'input' && (
                  item.input_type === 'checkbox' ?
                    <CheckboxLabel>
                      <Checkbox
                        type={item.input_type}
                        name={item.name.replace(/\s+/g, '-').toLowerCase()}
                        id={item.wordpress_id ? item.wordpress_id : item.name.replace(/\s+/g, '-').toLowerCase()}
                        className={item.class && item.class}
                        title={item.placeholder ? item.placeholder : item.name}
                        aria-label={item.placeholder ? item.placeholder : item.name}
                        onChange={this.handleChange}
                        required={item.required}
                      />
                      {item.label ? item.label : item.name}
                      {item.required && <span>*</span>}
                    </CheckboxLabel>
                    : 
                    <>
                      {item.label && <label dangerouslySetInnerHTML={{ __html: item.label }} />}
                      <input
                        type={item.input_type}
                        name={item.name.replace(/\s+/g, '-').toLowerCase()}
                        id={item.wordpress_id ? item.wordpress_id : item.name.replace(/\s+/g, '-').toLowerCase()}
                        className={item.class && item.class}
                        placeholder={item.placeholder}
                        title={item.placeholder ? item.placeholder : item.name}
                        aria-label={item.placeholder ? item.placeholder : item.name}
                        maxLength={item.maxlength}
                        onChange={item.input_type === 'file' ? this.handleAttachment : this.handleChange}
                        required={item.required}
                      />
                    </>
                )}
                {item.type === 'select' && 
                  <>
                    {item.label && <label dangerouslySetInnerHTML={{ __html: item.label }} />}
                    <select
                      name={item.name.replace(/\s+/g, '-').toLowerCase()}
                      id={item.label ? item.label : item.name.replace(/\s+/g, '-').toLowerCase()}
                      className={item.class}
                      title={item.placeholder}
                      aria-label={item.placeholder}
                      onChange={this.handleChange}
                      required={item.required}
                      defaultValue=""
                    >
                      {item.select_options.map((option, index) =>
                        <option
                          key={index}
                          value={option.disabled ? '' : option.option}
                          disabled={option.disabled}
                        >
                          {option.option}
                        </option>
                      )}
                    </select>
                  </>
                }
                {item.type === 'textarea' &&
                  <textarea
                    name={item.name.replace(/\s+/g, '-').toLowerCase()}
                    id={item.label ? item.label : item.name.replace(/\s+/g, '-').toLowerCase()}
                    className={item.class}
                    placeholder={item.placeholder}
                    title={item.placeholder ? item.placeholder : item.name}
                    aria-label={item.placeholder ? item.placeholder : item.name}
                    maxLength={item.maxlength}
                    onChange={this.handleChange}
                    required={item.required}
                  ></textarea>
                }
                {item.type === 'message' && 
                  <Message className="formMessage" dangerouslySetInnerHTML={{ __html: item.message }} />
                }
                {item.type === 'submit' &&
                  <FormButton value={item.button_label} primary />
                }          
              </Field>
            )}
            {/* </FormLeft> */}
            {/* <FormRight>
              {group.wordpress_fields.filter(e => e.type === 'textarea').map((item, index) => 
                <Field key={index} w={item.width ? item.width : '100%'}>
                  <textarea
                    name={item.name.replace(/\s+/g, '-').toLowerCase()}
                    id={item.label ? item.label : item.name.replace(/\s+/g, '-').toLowerCase()}
                    className={item.class}
                    placeholder={item.placeholder}
                    title={item.placeholder ? item.placeholder : item.name}
                    aria-label={item.placeholder ? item.placeholder : item.name}
                    maxLength={item.maxlength}
                    onChange={this.handleChange}
                    required={item.required}
                  ></textarea>
                </Field>
              )}
            </FormRight> */}
            {/* <FormBelow>
              {group.wordpress_fields.filter(e => e.type === 'message').map((item, index) =>
                <Field key={index} w={item.width ? item.width : '100%'}>
                  <Message className="formMessage" dangerouslySetInnerHTML={{ __html: item.message }} />
                </Field>
              )}
            </FormBelow> */}
          </Fields>
        )}
      </Form>
    )
  }
}

const Form = styled.form`
  margin: ${rem('45px')} 0 0 0;
`

const Fields = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  .field0 {
    grid-area: a;
  }
  .field1 {
    grid-area: b;
  }
  .field2 {
    grid-area: c;
  }
  .field3 {
    grid-area: d;
  }
  .field4 {
    grid-area: e;
  }
  .field5 {
    grid-area: f;
  }
  grid-template-areas: 
    "a"
    "b"
    "c"
    "e"
    "d"
    "f";
  @media (min-width: 992px) {
    grid-template-columns: 40% 60%;
    grid-template-areas: 
    "a e"
    "b e"
    "c e"
    "d e"
    "f f";
  }
`

const Field = styled.div`
`

const CheckboxLabel = styled.label`
  display: inline-block;
  position: relative;
  padding: 0 0 0 ${rem('30px')};
  span {
    color: red;
  }
`

const Checkbox = styled.input`
  ${position('absolute', '7px', '', '', '0')};
  width: auto;
`

const Message = styled.p`
  font-size: ${rem('12px')};
  font-weight: 100;
  letter-spacing: 0;
  margin-top: ${rem('45px')};
  text-align: center;
  @media (min-width: 1200px) {
    font-size: ${rem('14px')};
  }
`

const FormButton = styled(ButtonInput)`
  text-transform: none;
  font-size: ${rem('18px')};
  padding: ${rem('12px')} ${rem('75px')};
  &:hover, &:active, &:focus {
    background-color: #fff;
  }
  @media (min-width: 992px) {
    font-size: ${rem('20px')};
  }
`