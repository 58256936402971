import React from 'react'
import styled from 'styled-components'
import { rem, rgba } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'
import SVG from 'react-inlinesvg'

import { Container } from '../../Container'

import { MainColour, AltFont, PDBold, AltColour } from '../../../utils/variables'

// import IconMessage from '../../../images/icon-phone-messaging.svg'
// import IconEdit from '../../../images/icon-text-edit.svg'
// import IconEmail from '../../../images/icon-email-send.svg'
// import IconAi from '../../../images/icon-ai.svg'

class HomepageIcons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199,        
    };
  }
  render() {
    const data = this.props.data
    const { isTablet } = this.state
    return (
      <>
        {!isTablet && <IconLines />}
        <Icons data={data} />
      </>
    )
  }
}
export default HomepageIcons

const IconLines = () => (
  <SectionLines>
      <Controller>
        <Scene
          duration={300}
          offset={800}
          reverse={false}
          triggerElement={null}
        // indicators={true}
        >
          {(progress) => (
            <Tween
              from={{
                transform: 'scale(0)',
                opacity: '0'
              }}
              to={{
                transform: 'scale(1)',
                opacity: '1'
              }}
              totalProgress={progress}
              paused
            >
              <CircleEndpoint />
            </Tween>
          )}
        </Scene>
        <Scene
          duration={100}
          offset={1100}
          reverse={false}
          triggerElement={null}
        // indicators={true}
        >
          {(progress) => (
            <Tween
              from={{
                height: '0'
              }}
              to={{
                height: '120px'
              }}
              totalProgress={progress}
              paused
            >
              <VerticalLine style={{ marginLeft: '30px', marginTop: '10px' }} />
            </Tween>
          )}
        </Scene>
        <Scene
          duration={100}
          offset={1200}
          reverse={false}
          triggerElement={null}
        //indicators={true}
        >
          {(progress) => (
            <Tween
              from={{
                width: '0'
              }}
              to={{
                width: '994px'
              }}
              totalProgress={progress}
              paused
            >
              <HorizontalLine style={{ marginLeft: '30px' }} />
            </Tween>
          )}
        </Scene>
        <Scene
          duration={100}
          offset={1300}
          reverse={false}
          triggerElement={null}
        //indicators={true}
        >
          {(progress) => (
            <Tween
              from={{
                height: '0'
              }}
              to={{
                height: '180px'
              }}
              totalProgress={progress}
              paused
            >
              <VerticalLine style={{ float: 'right' }} />
            </Tween>
          )}
        </Scene>
        <Scene
          duration={100}
          offset={1400}
          reverse={false}
          triggerElement={null}
        //indicators={true}
        >
          {(progress) => (
            <Tween
              from={{
                width: '0'
              }}
              to={{
                width: '994px'
              }}
              totalProgress={progress}
              paused
            >
              <HorizontalLine style={{ float: 'right', marginTop: '178px' }} />
            </Tween>
          )}
        </Scene>
        <Scene
          duration={100}
          offset={1500}
          reverse={false}
          triggerElement={null}
        //indicators={true}
        >
          {(progress) => (
            <Tween
              from={{
                height: '0'
              }}
              to={{
                height: '180px'
              }}
              totalProgress={progress}
              paused
            >
              <VerticalLine style={{ marginLeft: '28px', marginTop: '180px' }} />
            </Tween>
          )}
        </Scene>
        <Scene
          duration={100}
          offset={1600}
          reverse={false}
          triggerElement={null}
        //indicators={true}
        >
          {(progress) => (
            <Tween
              from={{
                width: '0'
              }}
              to={{
                width: '499px'
              }}
              totalProgress={progress}
              paused
            >
              <HorizontalLine style={{ marginLeft: '28px' }} />
            </Tween>
          )}
        </Scene>
        <Scene
          duration={100}
          offset={1700}
          reverse={false}
          triggerElement={null}
        //indicators={true}
        >
          {(progress) => (
            <Tween
              from={{
                height: '0'
              }}
              to={{
                height: '180px'
              }}
              totalProgress={progress}
              paused
            >
              <VerticalLine style={{ marginLeft: '525px' }} />
            </Tween>
          )}
        </Scene>
        <Scene
          duration={200}
          offset={1800}
          reverse={false}
          triggerElement={null}
        //indicators={true}
        >
          {(progress) => (
            <Tween
              from={{
                transform: 'scale(0)',
                opacity: '0'
              }}
              to={{
                transform: 'scale(1)',
                opacity: '1'
              }}
              totalProgress={progress}
              paused
            >
              <CircleEndpoint style={{ marginLeft: '495px', marginTop: '10px' }} />
            </Tween>
          )}
        </Scene>
      </Controller>
  </SectionLines>
)

const SectionLines = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  width: 100%;
  max-width: 1024px;
  /* overflow: hidden; */
  display: none;
  @media (min-width: 1200px) {
    display: inline-block;
  }
`

export const VerticalLine = styled.div`
  width: 2px;
  background-color: ${rgba('#fff', 0.2)};
`

export const HorizontalLine = styled.div`
  height: 2px;
  background-color: ${rgba('#fff', 0.2)};
`

const CircleEndpoint = styled.div`
  height: 60px;
  width: 60px;
  background-color: ${MainColour};
  border-radius: 60px;
  position: relative;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &:before {    
    height: 10px;
    width: 10px;
    background-color: ${rgba('#fff', 0.2)};
    border-radius: 10px;
  }
  &:after {
    height: 80px;
    width: 80px;
    border: 2px solid ${MainColour};
    border-radius: 80px;
  }
`

const Icons = (props) => (
  <SectionLinesContent>
    <Container>
      <IconList>
        <Controller>
          <Scene
            duration={300}
            offset={-100}
            reverse={false}
            // indicators={true}
          >
            <Tween
              staggerFrom={{
                opacity: 0,
                cycle: {
                  scale: [0, 0.5],
                }
              }}
              stagger={0.1}
            >
              {props.data.map((icon,index) =>
                <IconCircle key={index} text={icon.label}>
                  <SVG src={icon.icon.localFile.publicURL} />
                </IconCircle>
              )}
            </Tween>
          </Scene>
        </Controller>
      </IconList>
    </Container>
  </SectionLinesContent>
)

const SectionLinesContent = styled.div`
  position: relative;
  z-index: 10;
  text-align: center;
  @media (min-width: 576px) {
    margin: ${rem('60px')} 0;
  }  
  @media (min-width: 1200px) {
    margin: ${rem('280px')} 0 ${rem('420px')} 0;
  }  
`

export const IconList = styled.div`
  display: inline-block;
  margin: 0 auto ${rem('60px')};
  max-width: 370px;
  @media (min-width: 576px) {
    width: 408px;
    max-width: 408px;
    margin: 0 auto ${rem('75px')};
  }
  @media (min-width: 768px) {
    max-width: 60%;
  }
  @media (min-width: 992px) {
    max-width: 100%;
    width: auto;
  }
`

export const IconCircle = styled.div`
  float: left;
  height: 180px;
  width: 180px;
  border-radius: 180px;
  background-color: ${AltColour};
  position: relative;
  /* margin-right: ${rem('10px')}; */
  margin-bottom: ${rem('20px')};
  transform: scale(0.75);
  max-width: 50%;
  &:nth-child(2) {
    margin-right: 0;
  }
  @media (min-width: 576px) {
    margin-right: ${rem('45px')};
    margin-bottom: 0;
    transform: scale(1);    
    &:nth-child(-n+2) {
      margin-bottom: ${rem('60px')};
    }    
  }  
  @media (min-width: 768px) {
    transform: none;
  }
  @media (min-width: 992px) {
    &:nth-child(-n+2) {
      margin-bottom: 0;
    }
     &:nth-child(2) {
       margin-right: ${rem('45px')};
     }
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  &:before {
    content: '';
    height: 180px;
    width: 180px;
    border-radius: 180px;
    border: 2px solid ${AltColour};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all 0.3s ease;
    display: none;
    @media (min-width: 576px) {
      display: inline-block;
    }
  }
  &:after {
    content: '${props => props.text}';
    font-size: ${rem('18px')};
    font-family: ${AltFont};
    font-weight: ${PDBold};
    line-height: 1.2;
    font-style: italic;
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translate(-50%,0);
    width: 100%;
    opacity: 1;
    transition: all 0.3s ease;
    color: #fff;
    text-align: center;
    @media (min-width: 768px) {
      bottom: -40px;
      opacity: 0;
    }
    @media (min-width: 1200px) {
      font-size: ${rem('24px')};
      width: 70%;
    }
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all 0.3s ease;
  }
  &:hover {
    svg {
      transform: translate(-50%,-50%) scale(1.05);
    }  
    &:before {
      transform: translate(-50%,-50%) scale(1.1);
    }  
    &:after {
      opacity: 1;
      bottom: -45px;
      @media (min-width: 576px) {
        bottom: -50px;
      }
      @media (min-width: 1200px) {
        bottom: -75px;
      }
    }
  }
`